<template>
  <div class="common-layout text-slate-500">
    <el-container>
      <el-header class="px-0">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          :ellipsis="false"
          @select="handleSelect"
        >
          <el-menu-item>
            <img
              style="width: 100px"
              src="./assets/images/csgo-panel-logo.jpg"
              alt="Element logo"
            />
          </el-menu-item>
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/nodes/properties">道具面板</el-menu-item>
          <el-menu-item index="/2">战术面板</el-menu-item>
          <el-menu-item index="/3">准星大全</el-menu-item>
          <el-menu-item index="/4">选手POV</el-menu-item>
          <el-menu-item index="/5">DEMO分析</el-menu-item>
        </el-menu>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <el-footer>
        <!--        <div-->
        <!--          class="max-w-7xl mx-auto divide-y divide-slate-200 px-4 sm:px-6 md:px-8 dark:divide-slate-700"-->
        <!--        >-->
        <!--          <div class="flex sm:inline-flex md:block lg:hidden xl:flex ...">-->
        <!--            123-->
        <!--          </div>-->
        <!--        </div>-->
      </el-footer>
    </el-container>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import router from "@/router";

const activeIndex = ref("/");
const handleSelect = (key: string) => {
  // 跳转路由
  router.push(key);
};
</script>

<style lang="scss">
.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: auto;
}
</style>
